import React from "react"

import Link from "../components/link"
import {BlogCard, BlogCardWide, BlogCardImage, CardHeader} from "../components/BlogCard"
import {ResponsiveGrid} from "../components/ResponsiveGrid"
import {ThemeContext, Box,  Paragraph, Text, Button, Heading,ResponsiveContext } from "grommet"

import Layout from "../components/layout"

import SEO from "../components/seo"


const IndexPage = ({ data }) => {

    const posts = data.posts.nodes;
    const [firstPost] = posts;

return (
  <Layout>

    <SEO title="Home" />

    <Box margin={{vertical:"small"}}/>




    <Box  margin={{top:"large"}} width="xlarge" >
      <Heading margin={{horizontal:"medium"}}>The Pregly Blog</Heading>
    </Box>

    <Box width="xlarge">
       <ResponsiveGrid gap="small" margin={{vertical:"small"}} columns="auto" rows="auto">
         {posts.map((post, idx) => (
           <BlogCard  post={post}/>
         ))}
       </ResponsiveGrid>
     </Box>




  </Layout>
);};

export default IndexPage


export const query = graphql`
query($category: String) {
    posts: allMdx(filter: { frontmatter: { template: { eq: "blog" },  category: {regex: $category}}}) {
      nodes {
        id
        body
        excerpt
        frontmatter {
          featuredImage
          date
          author
          title
          category
          path
        }
      }
    }
  }
`;
